const bodyScrollLock = require('body-scroll-lock');
const disableBodyScroll = bodyScrollLock.disableBodyScroll;
const enableBodyScroll = bodyScrollLock.enableBodyScroll;

document.addEventListener('DOMContentLoaded', function () {

    const el = document.getElementsByClassName('js-popup');

    const init = function () {

        const overlay = document.getElementById('overlay'),
            inside = overlay.getElementsByClassName('js-inside')[0],
            popup = document.getElementsByClassName('js-popup')[0],
            trigger = document.getElementsByClassName('js-showPopup')[0],
            closePopupButton = document.getElementById('js-popup-close');

        let target;

        let status = false;

        const checkHeight = function () {

            let wh = window.innerHeight,
                oh = popup.clientHeight;

            // if height of obj is bigger than window

            if (oh + 80 > wh) {
                if (status === false) {
                    inside.classList.add('is-block');
                    status = true;
                }

            } else {
                if (status = true) {
                    inside.classList.remove('is-block');
                    status = false;
                }
            }
        }

        const clickOutside = function (e) {

            if (e.target.closest('.js-popup') === null) {
                hidePopup();
            }
        };

        window.showPopup = function (e) {

            disableBodyScroll(inside);

            // overlay
            overlay.classList.add('is-active');

            setTimeout(function () {
                overlay.classList.add('is-visible');

                document.addEventListener('click', clickOutside);
            }, 1);

            // popup
            popup.classList.add('is-active');
            setTimeout(function () {
                popup.classList.add('is-visible');
            }, 1);


            window.addEventListener('resize', checkHeight);

            checkHeight();
        };

        const hidePopup = function (e) {
            enableBodyScroll(inside);

            overlay.classList.remove('is-active');
            overlay.classList.remove('is-visible');

            popup.classList.remove('is-active');
            popup.classList.remove('is-visible');

            document.removeEventListener('click', clickOutside);
            window.removeEventListener('resize', checkHeight);

            //e.preventDefault() ? e.preventDefault() : e.preventDefault = false;
        };


        // trigger.addEventListener('click', showPopup);
        closePopupButton.addEventListener('click', hidePopup);


        // Hide menu on ESC

        document.addEventListener('keydown', function (evt) {
            // evt = evt || window.event;
            var isEscape = false;
            if ("key" in evt) {
                isEscape = (evt.key == "Escape" || evt.key == "Esc");
            } else {
                isEscape = (evt.keyCode == 27);
            }
            if (isEscape) {
                hidePopup();
            }
        });
    }

    el.length > 0 ? init() : false;


}, false);
