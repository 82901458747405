document.addEventListener('DOMContentLoaded', function () {

    const el = document.getElementsByClassName('js-quantity');


    const init = function () {

        let index = 1;

        for (let i = 0; i < el.length; i++) {
            const minus = el[i].getElementsByClassName('js-minus')[0],
                plus = el[i].getElementsByClassName('js-plus')[0];

            const changeNum = function () {
                for (let j = 0; j < el.length; j++) {
                    input = el[j].getElementsByClassName('js-num')[0]
                    input.setAttribute('value', index);
                }
            };

            minus.addEventListener('click', function () {
                if (index > 1) {
                    index--;
                    changeNum();
                }
            });

            plus.addEventListener('click', function () {
                index++;
                changeNum();
            });
        }
    };


    el.length > 0 ? init() : false;

}, false);
