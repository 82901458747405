document.addEventListener('DOMContentLoaded', function() {

    const timers = document.getElementsByClassName('discount-timer');

    const countdown = function(timer, endDate) {
        let days, hours, minutes, seconds;
        
        endDate = new Date(endDate).getTime();

        if (isNaN(endDate)) {
            return;
        }
        
        setInterval(calculate, 1000);
        
        function calculate() {
	        
            let startDate = new Date();
                startDate = startDate.getTime();
            
            let timeRemaining = parseInt((endDate - startDate) / 1000);
            
            if (timeRemaining >= 0) {
                days = parseInt(timeRemaining / 86400);
                timeRemaining = (timeRemaining % 86400);
                
                hours = parseInt(timeRemaining / 3600);
                timeRemaining = (timeRemaining % 3600);
                
                minutes = parseInt(timeRemaining / 60);
                timeRemaining = (timeRemaining % 60);
                
                seconds = parseInt(timeRemaining);
                
                timer.querySelector("#days").innerHTML = parseInt(days, 10);
                timer.querySelector("#hours").innerHTML = ("0" + hours).slice(-2);
                timer.querySelector("#minutes").innerHTML = ("0" + minutes).slice(-2);
                timer.querySelector("#seconds").innerHTML = ("0" + seconds).slice(-2);
            } else {
                return;
            }
        }
        
        calculate();
    }

    for (var i = 0; i < timers.length; i++) {
        const time = timers[i].getAttribute('data-endtime')
        countdown(timers[i], time)
    }
    
}, false);
