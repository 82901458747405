import Vue from 'vue/dist/vue.js';

Vue.component('star-rating', {
  template: '#star-rating',
  props: ['max', 'current', 'rates'],
  computed: {
    getRating: function() {
      return (this.current / this.max) * 100
    }
  }
})

new Vue({
  el: '#app'
})